import React, { useState, useRef } from 'react';
import './FormComponent.css';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    age: '',
    phoneNumber: '',
    region: '',
    agreedToTerms: false,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEligible, setIsEligible] = useState(true);
  const [apiResponse, setApiResponse] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [smsSent, setSmsSent] = useState(false); 
  const [agreedToStudyInfo, setAgreedToStudyInfo] = useState(false);
  const [canAgree, setCanAgree] = useState(false);

  const scrollRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleRegionChange = (e) => {
    setFormData({
      ...formData,
      region: e.target.value,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;

    // Allow only valid characters: a single leading "+" and digits
    if (inputValue === '' || inputValue.match(/^\+?[0-9]*$/)) {
      setFormData({
        ...formData,
        phoneNumber: inputValue,
      });
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if the phone number starts with +41 or +49
    return phoneNumber.startsWith('+41') || phoneNumber.startsWith('+49');
  };

  const validateEligibility = (age, region, phoneNumber) => {
    // Check if the user is eligible to participate in the study
    if (age < 16 || age > 25) {
      return false;
    }
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(formData.phoneNumber)) {
      setErrorMessage('Bitte geben Sie eine gültige Telefonnummer ein, die mit +41 oder +49 beginnt.');
      return;
    }

    if (!validateEligibility(formData.age, formData.region, formData.phoneNumber)) {
      setApiResponse('Wir danken Ihnen für Ihr Interesse an der Studie. Leider sind Sie nicht berechtigt, an der Studie teilzunehmen, da wir nur Personen im Alter von 16 bis 25 Jahren in der Region Basel suchen.');
      setIsEligible(false);
      return;
    }

    /*if (!formData.agreedToTerms) {
      setErrorMessage('Sie müssen den Bedingungen zustimmen.');
      return;
    }*/

    // Clear the error message if everything is valid
    setErrorMessage('');

    // Make a request to send an SMS verification code
    const url_sms = 'https://tap-study.ch:8080/api/sendOTP';
    const requestData = {
      phone: formData.phoneNumber,
    };

    fetch(url_sms, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 1) {
          setSmsSent(true);
          setApiResponse(response.message);
        } else {
          setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      });

    console.log('Form data:', formData);
  };

  const handleVerification = (e) => {
    e.preventDefault(); 

    const url_signup = 'https://tap-study.ch:8080/api/submitSignUpForm';
    const requestData = {
      otp: verificationCode,
      region: formData.region,
      age: formData.age,
      phone: formData.phoneNumber,
    };

    fetch(url_signup, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 1) {
          setApiResponse(response.message);
          setIsSubmitted(true);
        } else if (response.status === 0) {
          setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        } else if (response.status === 2) {
          setErrorMessage(response.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      });

    console.log('Form data:', formData);
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const element = scrollRef.current as HTMLElement;
      const scrollPosition = element.scrollTop + element.clientHeight;
      const threshold = 50; // Small buffer to account for rounding differences
      const bottom = element.scrollHeight - scrollPosition <= threshold;
  
      if (bottom) {
        setCanAgree(true);
      }
    }
  };

  const handleAgree = () => {
    setAgreedToStudyInfo(true);
    setShowSignUp(true);
  };

  if (isSubmitted) {
    return (
      <div className="thank-you-message">
        <h2>Vielen Dank für Ihre Anmeldung!</h2>
        <p>{apiResponse}</p>
      </div>
    );
  }

  if (!isEligible) {
    return (
      <div className="thank-you-message">
        <h2>Vielen Dank für Ihr Interesse an der Studie!</h2>
        <p>{apiResponse}</p>
      </div>
    );
  }

  if (!showSignUp) {
    return (
      <div className="info-container">
        <h2>Informationen zur Studie</h2>
        <div className="scroll-box" onScroll={handleScroll} ref={scrollRef}>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   

  Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   

  Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   

  Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.   

  Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.   

  At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed tempor et et invidunt justo labore Stet clita ea et gubergren, kasd magna no rebum. sanctus sea sed takimata ut vero voluptua. est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.   

  Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus.   

  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   

  Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.   

  Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.   

  Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
            {/* Add as much text as you want to require the user to scroll */}
            ... {/* Add long text content here */}
          </p>
        </div>
        <button
          className="agree-button"
          onClick={handleAgree}
          disabled={!canAgree} // Only enable the button when scrolled to the bottom
        >
          Ich stimme zu
        </button>
      </div>
    );
  }

  if (smsSent) {
    return (
      <div className="verification-container">
        <h2>Bitte geben Sie den per SMS gesendeten Code ein</h2>
        <form onSubmit={handleVerification}>
          <div className="form-group">
            <label>
              Bestätigungscode:
              <input
                type="number"
                name="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </label>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button className="submit-button" type="submit">Code bestätigen</button>
        </form>
      </div>
    );
  }

  return (
    <div className="form-container">
      <h2 className="form-title">Anmeldung zur Personen-Zeit-Verhalten-Studie</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Alter:
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Telefon Nummer:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handlePhoneNumberChange}
              required
              placeholder="+41797005846"
            />
          </label>
        </div>
        <div className="form-group">
          <label>Region:</label>
          <div>
            <label>
              <input
                type="radio"
                name="region"
                value="basel"
                checked={formData.region === 'basel'}
                onChange={handleRegionChange}
                required
              />
              Ich wohne in der Region Basel
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="region"
                value="nicht-basel"
                checked={formData.region === 'nicht-basel'}
                onChange={handleRegionChange}
                required
              />
              Ich wohne ausserhalb der Region Basel
            </label>
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {/*<div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              name="agreedToTerms"
              checked={formData.agreedToTerms}
              onChange={handleChange}
              required
            />
            Ich stimme der&nbsp;
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              Datenschutzerklärung
            </a>
            &nbsp;zu.
          </label>
        </div>*/}
        <button className="submit-button" type="submit">Registrieren</button>
      </form>
    </div>
  );
};

export default FormComponent;
