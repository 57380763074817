import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';
import React, { useState } from 'react';

// Define the Hour type
interface Activity {
    type: string;
    startTime: string;
    endTime: string;
    activityName: string;
}

interface Schedule {
    [key: string]: { activities: Activity[], wakeUpTime: string, schoolStart: string, schoolEnd: string } | null;
}

interface Props {
    schedule: Schedule;
    scheduleFunction: React.Dispatch<React.SetStateAction<Schedule>>;
    screenFunction: React.Dispatch<React.SetStateAction<string>>;
    currentDay: string;
}

const ActivityManagement: React.FC<Props> = ({ schedule, scheduleFunction, screenFunction, currentDay }) => {
    const confirmButtonStyle = {
        backgroundColor: "#A5D7D2",
      };
    let initialActivities: Activity[] = [];
    let schoolStart = '';
    let schoolEnd = '';
    if (schedule[currentDay] && schedule[currentDay]['activities']) {
        initialActivities = schedule[currentDay]['activities'];
    }
    if (schedule[currentDay]) {
        schoolStart = schedule[currentDay]['schoolStart']; // Corrected to schoolStart
        schoolEnd = schedule[currentDay]['schoolEnd']; // Corrected to schoolEnd
    }

    // Use the Activity type for the state
    const [activities, setActivities] = useState<Activity[]>(initialActivities);

    const handleAddActivity = () => {
        screenFunction('activityYes');
        return;
    };

    const handleDeleteActivity = (index: number) => {
        const newActivities = activities.filter((_, i) => i !== index);
        setActivities(newActivities);
    };

    const handleConfirm = () => {
        scheduleFunction((prevSchedule) => ({
            ...prevSchedule,
            [currentDay]: {
                ...prevSchedule[currentDay],
                activities: activities,
            },
        }));
        screenFunction('activity');
    };

    if (activities.length === 0) {
        return (
            <div>
                <button onClick={handleAddActivity}>Aktivität hinzufügen</button>
                <h3 style={{ marginTop: '5vh' }}>Deine Aktivitäten</h3>
                <p>Keine Aktivitäten</p>
                <button style={confirmButtonStyle} onClick={handleConfirm}>Bestätigen</button>
            </div>
        );
    }

    return (
        <div>
            <button onClick={handleAddActivity}>Aktivität hinzufügen</button>
            <h3 style={{ marginTop: '5vh' }}>Deine Aktivitäten am {currentDay}</h3>
            <ul>
                {activities.map((activity, index) => (
                    <li key={index}>
                        {activity.activityName} ({activity.startTime} - {activity.endTime}) {' '}
                        <button onClick={() => handleDeleteActivity(index)}>Löschen</button>
                    </li>
                ))}
            </ul>
            <button onClick={handleConfirm}>Bestätigen</button>
        </div>
    );
};

export default ActivityManagement;
