import React, { useState } from 'react';

// Define the Hour type
interface Hour {
    start: string;
    end: string;
}

interface Schedule {
    [key: string]: { wakeUpTime: Hour[], freeHours?: Hour[], schoolStart: string, schoolEnd: string } | null;
}

interface Props {
    schedule: Schedule;
    scheduleFunction: React.Dispatch<React.SetStateAction<Schedule>>;
    screenFunction: React.Dispatch<React.SetStateAction<string>>;
    currentDay: string;
}

const FreeHoursInput: React.FC<Props> = ({ schedule, scheduleFunction, screenFunction, currentDay }) => {
    let initialHours: Hour[] = [];
    let schoolStart = '';
    let schoolEnd = '';
    if (schedule[currentDay] && schedule[currentDay]['freeHours']) {
        initialHours = schedule[currentDay]['freeHours'];
    }
    if (schedule[currentDay]) {
        schoolStart = schedule[currentDay]['schoolStartTime'];
        schoolEnd = schedule[currentDay]['schoolEndTime'];
    }

    // Use the Hour type for the state
    const [hours, setHours] = useState<Hour[]>(initialHours);
    const [startHour, setStartHour] = useState('');
    const [endHour, setEndHour] = useState('');
    const [error, setError] = useState('');

    const handleAddHour = () => {
        if (startHour && endHour) {
            if (endHour > startHour) {
                if (startHour >= schoolStart && endHour <= schoolEnd) {
                    setHours([...hours, { start: startHour, end: endHour }]);
                    setStartHour('');
                    setEndHour('');
                    setError('');
                } else {
                    setError(`Die freien Stunden müssen zwischen ${schoolStart} und ${schoolEnd} liegen`);
                }
            } else {
                setError('Endzeit muss nach Anfangszeit liegen');
            }
        }
    };

    const handleStartHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartHour(e.target.value);
    };

    const handleEndHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndHour(e.target.value);
    };

    const handleDeleteHour = (index: number) => {
        const newHours = hours.filter((_, i) => i !== index);
        setHours(newHours);
    };

    const handleConfirm = () => {
        scheduleFunction((prevSchedule) => ({
            ...prevSchedule,
            [currentDay]: {
                ...prevSchedule[currentDay],
                freeHours: hours,
            },
        }));
        screenFunction('activity');
    };

    return (
        <div>
            <h2>Gib deine Zwischenstunden ein:</h2>
            <p>Schulbeginn: {schoolStart}</p>
            <p>Schulende: {schoolEnd}</p>
            <label htmlFor="startHour">Anfangszeit Zwischenstunde:</label>
            <input
                type="time"
                id="startHour"
                name="startHour"
                value={startHour}
                onChange={handleStartHourChange}
                required
            />
            <label htmlFor="endHour">Endezeit Zwischenstunde:</label>
            <input
                type="time"
                id="endHour"
                name="endHour"
                value={endHour}
                onChange={handleEndHourChange}
                required
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button onClick={handleAddHour}>Stunde hinzufügen</button>
            
            <h3 style={{ marginTop: '5vh' }}>Deine Zwischenstunden am {currentDay}</h3>
            <ul>
                {hours.map((hour, index) => (
                    <li key={index}>
                        {hour.start} - {hour.end}{' '}
                        <button onClick={() => handleDeleteHour(index)}>Löschen</button>
                    </li>
                ))}
            </ul>
            <button onClick={handleConfirm}>Bestätigen</button>
        </div>
    );
};

export default FreeHoursInput;
