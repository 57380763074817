import React, { useEffect, useState } from 'react';
import './styles.css';
import HourComponent from './HourComponent.tsx'; // Ensure this import matches your file structure
import FreeHoursInput from './FreeHours.tsx';
import Survey from './Survey.tsx';
import ProgressBar from './ProgressBar.tsx';
import ActivityManagement from './ActivityManagement.tsx';

import TimetableComponent from './Timetable.tsx'; // Ensure this import matches your file structure

const Calendar = () => {
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(() => {
    return sessionStorage.getItem('isSurveySubmitted') === 'true'; // If localStorage has 'true', set it to true, else false
  });

  // Load the initial state of `surveyData` from localStorage (default is null)
  const [surveyData, setSurveyData] = useState(() => {
    const savedSurveyData = sessionStorage.getItem('surveyData');
    return savedSurveyData ? JSON.parse(savedSurveyData) : null; // Parse saved survey data or return null
  });

  // Function to handle survey submission and store the survey data
  const handleSurveySubmit = (data) => {
    setSurveyData(data); // Save the survey data
    setIsSurveySubmitted(true); // When survey is submitted, switch to the calendar view
    sessionStorage.setItem('isSurveySubmitted', 'true'); // Store the survey submission status in localStorage
    sessionStorage.setItem('surveyData', JSON.stringify(data)); // Store the survey data in localStorage
  };

  const days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  const questionTypes = ['wakeUpTime', 'schoolStartTime', 'lunchTime', 'lunchEndTime', 'schoolEndTime', 'sleepTime'];
  const hours = Array.from({ length: 48 }, (_, i) => `${Math.floor(i / 2)}:${i % 2 === 0 ? '00' : '30'}`);

  const initialScheduleState = days.reduce((acc, day) => ({
    ...acc,
    [day]: {
      ...questionTypes.reduce((innerAcc, question) => ({
        ...innerAcc,
        [question]: null
      }), {}),
      activities: [], 
      freeHours: []
    }
  }), {});

  function safeParse(json: string | null, fallback: any) {
    if (json === null) return fallback;
    try {
      return JSON.parse(json);
    } catch (error) {
      return fallback;
    }
  }

  const [schedule, setSchedule] = useState(
    safeParse(sessionStorage.getItem('schedule'), initialScheduleState)
  );
  const [currentDayIndex, setCurrentDayIndex] = useState(
    parseInt(sessionStorage.getItem('currentDayIndex') || '0')
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [warningMessage, setWarningMessage] = useState('');
  const [currentScreen, setCurrentScreen] = useState(
    sessionStorage.getItem('currentScreen') || 'question'
  );
  const [sleepAfterMidnight, setSleepAfterMidnight] = useState(false);
  const [skipSchool, setSkipSchool] = useState(false);
  const [skipLunch, setSkipLunch] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [editingDayIndex, setEditingDayIndex] = useState(-1);
  const [reachable, setReachable] = useState(false);
  const [validLink, setValidLink] = useState(false); // New state for link validity
  const [loading, setLoading] = useState(true); // New state for loading status

  // Effect for loading session storage data when component mounts
  useEffect(() => {
    console.log('Loading session storage data');
    const storedSchedule = sessionStorage.getItem('schedule');
    const storedCurrentDayIndex = sessionStorage.getItem('currentDayIndex');
    const storedCurrentScreen = sessionStorage.getItem('currentScreen');

    if (storedSchedule) {
      console.log('Setting schedule from session storage');
      setSchedule(JSON.parse(storedSchedule));
    }
    if (storedCurrentDayIndex) {
      console.log('Setting current day index from session storage');
      setCurrentDayIndex(parseInt(storedCurrentDayIndex));
    }
    if (storedCurrentScreen) {
      console.log('Setting current screen from session storage');
      setCurrentScreen(storedCurrentScreen);
    }
  }, []); // Empty dependency array means this effect runs once on mount

  // Effects for updating session storage when state changes
  useEffect(() => {
    console.log('Updating session storage data for schedule');
    sessionStorage.setItem('schedule', JSON.stringify(schedule));
  }, [schedule]); // This effect runs when `schedule` changes

  useEffect(() => {
    console.log('Updating session storage data for current day index');
    sessionStorage.setItem('currentDayIndex', currentDayIndex.toString());
  }, [currentDayIndex]); // This effect runs when `currentDayIndex` changes

  useEffect(() => {
    console.log('Updating session storage data for current screen');
    sessionStorage.setItem('currentScreen', currentScreen);
  }, [currentScreen]); // This effect runs when `currentScreen` changes


  const searchParams = new URLSearchParams(document.location.search)

  let userID: string|null = searchParams.get('userID');

  useEffect(() => {
    if (userID) {
      const url_check = 'https://tap-study.ch:8080/api/checkValiditySurvey1';
      const requestData = {
          type: 1,
          userID: userID,
      };

      fetch(url_check, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
      })
      .then(response => response.json())
      .then((response: {status: number, message: string}) => {
        console.log('Response Status:' + response.status);
        if (response.status === 1) {
          setValidLink(true); // Set the link as valid
        } else {
          setValidLink(false); // Invalid link
        }
        setLoading(false); // Stop loading once response is received
      })
      .catch((error) => {
          console.error('Error:', error);
          setValidLink(false); // Set as invalid on error
          setLoading(false);
      });
    } else {
      setValidLink(false);
      setLoading(false);
    }
  }, [userID]); // Fetch validity check only on first load

  // If loading, show a loading message
  if (loading) {
    return (<div>Loading...</div>);
  }

  // If the link is invalid, show an error message
  if (!validLink) {
    return (
      <div>
        <h1>Dieser Link ist leider falsch oder abgelaufen!</h1>
      </div>
    );
  }
  console.log(schedule);

  const adjustedHours = sleepAfterMidnight ? hours.slice(0, 8).map(hour => {
    const [h, m] = hour.split(':');
    const adjustedHour = `${parseInt(h) + 24}:${m}`;
    return adjustedHour;
  }) : hours;

  const handleAddActivity = (day, type, startTime, endTime, activityName) => {
    // might want to add validation here
    const updatedSchedule = {
      ...schedule,
      [day]: {
        ...schedule[day],
        activities: [
          ...schedule[day].activities,
          { type, startTime, endTime, activityName }
        ]
      }
    };
  
    setSchedule(updatedSchedule);
    setCurrentScreen('activity');
  };

  const submitSchedule = (schedule) => {
    const url_places = 'https://tap-study.ch:8080/api/submitSchedule';
      const requestData = {
          userID: userID, // Make sure this is structured as expected by your backend
          schedule: schedule,
          reachable: reachable,
          surveyData: surveyData
      };

      fetch(url_places, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
      })
      .then(response => response.json())
      .then((response: {status: number, message: string}) => {
        if (response.status === 1) {
          setCurrentScreen('feedback');
        }
        setApiResponse(response.message);  // Set the API response message
        console.log('Success:', response);
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  };

  const handleFeedback = (feedback) => {
    const url_places = 'https://tap-study.ch:8080/api/submitFeedback';
    const requestData = {
        userID: userID, // Make sure this is structured as expected by the backend
        feedback: feedback
    };

    fetch(url_places, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    })
    .then(response => response.json())
    .then((response: {status: number, message: string}) => {
      if (response.status === 1) {
        setApiResponse(response.message);
        setCurrentScreen('apiResponse');
    }})
    .catch((error) => {
        console.error('Error:', error);
    });
    setCurrentScreen('apiResponse');
  }

  function resetAllCalendarVariables() {
    sessionStorage.removeItem('schedule');
    sessionStorage.removeItem('currentDayIndex');
    sessionStorage.removeItem('currentScreen');

    setSchedule(initialScheduleState);
    setCurrentDayIndex(0);
    setCurrentScreen('question');
    setWarningMessage('');
    setSleepAfterMidnight(false);
    setSkipSchool(false);
    setSkipLunch(false);
    setApiResponse('');
    setCurrentTime('');
    setEditingDayIndex(-1);
    setReachable(false);

    //reset form
    const dayForm = document.getElementById('dayForm') as HTMLFormElement;
    if (dayForm) {
      dayForm.reset();
    }
  }

  const handleBack = () => {
    setWarningMessage('');
    if (currentScreen == 'calendar') {
      setCurrentScreen('question'); 
      return
    } else if (currentScreen == 'activity') {
        setCurrentScreen('question');
        return;      
    } else if (currentScreen == 'activityYes') {
        setCurrentScreen('activity');
        return;
    } else if (currentScreen == 'ReviewScreen') {
        setCurrentScreen('activity');
        return;
    } else if (currentScreen == 'calendarEdit') {
        if (editingDayIndex != -1) {
          setEditingDayIndex(-1);
        } else {
          setCurrentScreen('ReviewScreen');
        }
        return;
    } else if (currentScreen == 'question') {
        if (currentDayIndex > 0) {
          setCurrentDayIndex(currentDayIndex - 1);
          setCurrentScreen('activity');
          return;
        }
        return;
    } else if (currentScreen == 'freeHoursNew') {
      setCurrentScreen('question');
      return;
    } else if (currentScreen == 'activityManagement') {
      setCurrentScreen('activity');
      return;
    }
    setWarningMessage('');
  };

  const activityYes = () => {
    setCurrentScreen('activityYes');
  }

  const activityNo = () => {
    if (currentDayIndex === 6) {
      setCurrentScreen('ReviewScreen');
    } else {
      setCurrentDayIndex(currentDayIndex + 1);
      setCurrentScreen('question');
    }
    
  }

  function resetPopup() {
    const reset = window.confirm('Möchtest du den Kalender zurücksetzen?');
    if (reset) {
      resetAllCalendarVariables();
    }
  }

  function resetAllSurveyVariables() {
    sessionStorage.removeItem('isSurveySubmitted');
    sessionStorage.removeItem('surveyData');
    setIsSurveySubmitted(false);
    setSurveyData(null);
  }

  function resetAll() {
    resetAllCalendarVariables();
    resetAllSurveyVariables();
  }

  const convertTimeToMinutes = (time, allowNextDay = false) => {
    if (!time) return null;
    let [hour, minute] = time.split(':').map(Number);
    return hour * 60 + minute;
  };

  // If survey has not been submitted, render the survey first
  if (!isSurveySubmitted) {
    return <Survey onSubmit={handleSurveySubmit} />; // Pass the survey handler to capture results
  }

  if (currentScreen === 'question') {
    function skipSchoolEdit() {
      const skipSchoolElement = document.getElementById('skipSchool') as HTMLInputElement;
      //if checked
      if (skipSchoolElement && skipSchoolElement.checked) {
        const schoolStartTimeElement = document.getElementById('schoolStartTime');
        if (schoolStartTimeElement) {
          schoolStartTimeElement.setAttribute('disabled', 'true');
        }
        const freeTimesButton = document.getElementById('freeTimesButton');
        if (freeTimesButton) {
          freeTimesButton.setAttribute('disabled', 'true');
        }
        
        const schoolEndTimeElement = document.getElementById('schoolEndTime');
        if (schoolEndTimeElement) {
          schoolEndTimeElement.setAttribute('disabled', 'true');
        }
      } else {
        const schoolStartTimeElement = document.getElementById('schoolStartTime');
        if (schoolStartTimeElement) {
          schoolStartTimeElement.removeAttribute('disabled');
        }
        const freeTimesButton = document.getElementById('freeTimesButton');
        if (freeTimesButton) {
          freeTimesButton.removeAttribute('disabled');
        }
        const schoolEndTimeElement = document.getElementById('schoolEndTime');
        if (schoolEndTimeElement) {
          schoolEndTimeElement.removeAttribute('disabled');
        }
      }
    }
    function skipLunchEdit() {

      const skipLunchElement = document.getElementById('skipLunch') as HTMLInputElement;
      //if checked

      if (skipLunchElement && skipLunchElement.checked) {
        const lunchTimeElement = document.getElementById('lunchTime');
        const lunchEndTimeElement = document.getElementById('lunchEndTime');
        if (lunchTimeElement && lunchEndTimeElement) {
          lunchTimeElement.setAttribute('disabled', 'true');
          lunchEndTimeElement.setAttribute('disabled', 'true');
        }
      } else {
        const lunchTimeElement = document.getElementById('lunchTime');
        const lunchEndTimeElement = document.getElementById('lunchEndTime');
        if (lunchTimeElement && lunchEndTimeElement) {
          lunchTimeElement.removeAttribute('disabled');
          lunchEndTimeElement.removeAttribute('disabled');
        }
      }
    }

    function sleepAfterMidnightEdit() {
      const sleepAfterMidnightElement = document.getElementById('sleepAfterMidnightCheckbox') as HTMLInputElement;
      //if checked
      if (sleepAfterMidnightElement && sleepAfterMidnightElement.checked) {
        const sleepAfterMidnightID = document.getElementById('sleepAfterMidnightID');
        if (sleepAfterMidnightID) {
          sleepAfterMidnightID.setAttribute('value', '1');
        }
      } else {
        const sleepAfterMidnightID = document.getElementById('sleepAfterMidnightID');
        if (sleepAfterMidnightID) {
          sleepAfterMidnightID.setAttribute('value', '0');
        }
      }
    } 

    const convertMinutesToTime = (totalMinutes) => {
      if (totalMinutes == null) return null;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
  
      // Format hours and minutes to always be two digits
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
  
      return `${formattedHours}:${formattedMinutes}`;
    };

    const initiateFreeTimesWindow = () => {
      let dayForm = document.getElementById('dayForm') as HTMLFormElement;
      if (dayForm) {
        let formData = new FormData(dayForm);
        let wakeupTime = formData.get('wakeupTime');
        let schoolStartTime = formData.get('schoolStartTime');
        let lunchTime = formData.get('lunchTime');
        let lunchEndTime = formData.get('lunchEndTime');
        let schoolEndTime = formData.get('schoolEndTime');
        let sleepTime = formData.get('sleepTime');
        let sleepAfterMidnight = formData.get('sleepAfterMidnight');

        const errorLabel = document.getElementById('errorLabelEdit') as HTMLLabelElement;

        const skipSchoolElement = document.getElementById('skipSchool') as HTMLInputElement;
        const skipLunchElement = document.getElementById('skipLunch') as HTMLInputElement;

        if (wakeupTime == "" || sleepTime == "") {
          errorLabel.innerHTML = 'Bitte fülle alle Felder aus.';
          return;
        }

        if (skipSchoolElement && skipSchoolElement.checked) {
          schoolStartTime = null;
          schoolEndTime = null;
        } 

        if (skipLunchElement && skipLunchElement.checked) {
          lunchTime = null;
          lunchEndTime = null;
        }

        console.log('sleepAfterMidnight', sleepAfterMidnight);

        if (sleepAfterMidnight && sleepAfterMidnight == '1' && sleepTime != null) {
          sleepTime = convertTimeToMinutes(sleepTime) + 1440;
          sleepTime = convertMinutesToTime(sleepTime);
          console.log('sleepTime', sleepTime);
        }

        if (errorLabel) {
          //school starttime and lunch and schoolend time and sleep time should be after wakeup time
          if (schoolStartTime != null && convertTimeToMinutes(schoolStartTime) < convertTimeToMinutes(wakeupTime)) {
            errorLabel.innerHTML = 'Schul-/Arbeitsbeginn muss nach dem Aufwachen liegen.';
            return;
          } else if (lunchTime != null && convertTimeToMinutes(lunchTime) < convertTimeToMinutes(wakeupTime)) {
            errorLabel.innerHTML = 'Mittagspause muss nach dem Aufwachen liegen.';
            return;
          } else if (lunchEndTime != null && convertTimeToMinutes(lunchEndTime) < convertTimeToMinutes(lunchTime)) {
            errorLabel.innerHTML = 'Mittagspauseende muss nach dem Mittagspausebeginn liegen.';
            return;
          }
          else if ( schoolEndTime != null && convertTimeToMinutes(schoolEndTime) < convertTimeToMinutes(schoolStartTime)) {
            errorLabel.innerHTML = 'Schul-/Arbeitsende muss nach dem Schul-/Arbeitsbeginn liegen.';
            return;
          } else if (schoolEndTime != null && convertTimeToMinutes(sleepTime) < convertTimeToMinutes(schoolEndTime) && !sleepAfterMidnight) {
            errorLabel.innerHTML = 'Schlafenszeit muss nach dem Schul-/Arbeitsende liegen.';
            return;
          } else if (convertTimeToMinutes(sleepTime) < convertTimeToMinutes(wakeupTime)) {
            errorLabel.innerHTML = 'Schlafenszeit muss nach dem Aufwachen liegen.';
            return;
          } else {
            errorLabel.innerHTML = '';
          }
        }

        let updatedSchedule = {
          ...schedule,
          [days[currentDayIndex]]: {
            ...schedule[days[currentDayIndex]],
            wakeUpTime: wakeupTime,
            schoolStartTime: schoolStartTime,
            lunchTime: lunchTime,
            lunchEndTime: lunchEndTime,
            schoolEndTime: schoolEndTime,
            sleepTime: sleepTime
          }
        };
        setSchedule(updatedSchedule);
      }
      setCurrentScreen('freeHoursNew');
    }

    let wakeupTime = schedule[days[currentDayIndex]]['wakeUpTime'];
    let schoolStartTime = schedule[days[currentDayIndex]]['schoolStartTime'];
    let lunchTime = schedule[days[currentDayIndex]]['lunchTime'];
    let lunchEndTime = schedule[days[currentDayIndex]]['lunchEndTime'];
    let schoolEndTime = schedule[days[currentDayIndex]]['schoolEndTime'];
    let sleepTime = schedule[days[currentDayIndex]]['sleepTime'];

    let percentage = Math.round((currentDayIndex / 6) * 90);

    return (
      console.log('currentDay', days[currentDayIndex]),
      console.log('Survey Data', surveyData),
      <>
        <ProgressBar percentage={percentage} />
      <div className='editDayScreen'>
          
          <button onClick={() => handleBack()}>Zurück</button>

          <h1>Kalender für {days[currentDayIndex]}</h1>
          <form id='dayForm' onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const wakeupTime = formData.get('wakeupTime');
            let schoolStartTime = formData.get('schoolStartTime');
            let lunchTime = formData.get('lunchTime');
            let lunchEndTime = formData.get('lunchEndTime');
            let schoolEndTime = formData.get('schoolEndTime');
            let sleepTime = formData.get('sleepTime');
            let sleepAfterMidnight = formData.get('sleepAfterMidnight');

            const errorLabel = document.getElementById('errorLabelEdit') as HTMLLabelElement;
            //Ensure that the wakeup time is after the sleep time from the day before
            if (currentDayIndex > 0) {
              let previousDay = days[currentDayIndex - 1];
              let previousSleepTime = schedule[previousDay]['sleepTime'];
              if (convertTimeToMinutes(wakeupTime) < (convertTimeToMinutes(previousSleepTime) - 1400)) {
                errorLabel.innerHTML = 'Aufwachzeit muss nach der Schlafenszeit vom Vortag liegen.';
                return;
              }
            }


            const skipSchoolElement = document.getElementById('skipSchool') as HTMLInputElement;
            const skipLunchElement = document.getElementById('skipLunch') as HTMLInputElement;

            if (skipSchoolElement && skipSchoolElement.checked) {
              schoolStartTime = null;
              schoolEndTime = null;
            } 

            if (skipLunchElement && skipLunchElement.checked) {
              lunchTime = null;
              lunchEndTime = null;
            }

            console.log('sleepAfterMidnight', sleepAfterMidnight);

            if (sleepAfterMidnight && sleepAfterMidnight == '1' && sleepTime != null) {
              sleepTime = convertTimeToMinutes(sleepTime) + 1440;
              sleepTime = convertMinutesToTime(sleepTime);
              console.log('sleepTime', sleepTime);
            }

            if (errorLabel) {
              //school starttime and lunch and schoolend time and sleep time should be after wakeup time
              if (schoolStartTime != null && convertTimeToMinutes(schoolStartTime) < convertTimeToMinutes(wakeupTime)) {
                errorLabel.innerHTML = 'Schul-/Arbeitsbeginn muss nach dem Aufwachen liegen.';
                return;
              } else if (lunchTime != null && convertTimeToMinutes(lunchTime) < convertTimeToMinutes(wakeupTime)) {
                errorLabel.innerHTML = 'Mittagspause muss nach dem Aufwachen liegen.';
                return;
              } else if (lunchEndTime != null && convertTimeToMinutes(lunchEndTime) < convertTimeToMinutes(lunchTime)) {
                errorLabel.innerHTML = 'Mittagspauseende muss nach dem Mittagspausebeginn liegen.';
                return;
              }
              else if ( schoolEndTime != null && convertTimeToMinutes(schoolEndTime) < convertTimeToMinutes(schoolStartTime)) {
                errorLabel.innerHTML = 'Schul-/Arbeitsende muss nach dem Schul-/Arbeitsbeginn liegen.';
                return;
              } else if (schoolEndTime != null && convertTimeToMinutes(sleepTime) < convertTimeToMinutes(schoolEndTime) && !sleepAfterMidnight) {
                errorLabel.innerHTML = 'Schlafenszeit muss nach dem Schul-/Arbeitsende liegen.';
                return;
              } else if (convertTimeToMinutes(sleepTime) < convertTimeToMinutes(wakeupTime)) {
                errorLabel.innerHTML = 'Schlafenszeit muss nach dem Aufwachen liegen.';
                return;
              } else {
                errorLabel.innerHTML = '';
              }
            }

            let updatedSchedule = {
              ...schedule,
              [days[currentDayIndex]]: {
                ...schedule[days[currentDayIndex]],
                wakeUpTime: wakeupTime,
                schoolStartTime: schoolStartTime,
                lunchTime: lunchTime,
                lunchEndTime: lunchEndTime,
                schoolEndTime: schoolEndTime,
                sleepTime: sleepTime
              }
            };
            setSchedule(updatedSchedule);
            (e.target as HTMLFormElement).reset();
            const lunchTimeElement = document.getElementById('lunchTime');
            const lunchEndTimeElement = document.getElementById('lunchEndTime');
            if (lunchTimeElement && lunchEndTimeElement) {
              lunchTimeElement.removeAttribute('disabled');
              lunchEndTimeElement.removeAttribute('disabled');
            }

            const schoolStartTimeElement = document.getElementById('schoolStartTime');
            if (schoolStartTimeElement) {
              schoolStartTimeElement.removeAttribute('disabled');
            }
            const schoolEndTimeElement = document.getElementById('schoolEndTime');
            if (schoolEndTimeElement) {
              schoolEndTimeElement.removeAttribute('disabled');
            }
            setCurrentScreen('activity');
          }}>
            <div className='checkboxContainer'>
              <div className='checkboxLabel'>
                <input type='checkbox' id='skipSchool' onClick={skipSchoolEdit} name='skipSchool' value='skipSchool' />
                <label htmlFor="skipSchool">Keine Schule/Arbeit</label>
              </div>
              <div className='checkboxLabel'>
                <input type='checkbox' id='skipLunch' onClick={skipLunchEdit} name='skipLunch' value='skipLunch' />
                <label htmlFor="skipLunch">Keine Mittagspause</label>
              </div>
              <div className='checkboxLabel'>
                <input type='checkbox' id='sleepAfterMidnightCheckbox' onClick={sleepAfterMidnightEdit} name='sleepAfterMidnightCheckbox' value='sleepAfterMidnight' />
                <label htmlFor="sleepAfterMidnightCheckbox">Ich schlafe nach Mitternacht</label>
              </div>
            </div>
            <div className='dayLabels'>
            <label htmlFor="wakeupTime">Aufwachzeit:</label>
            <input type="time" id="wakeupTime" name="wakeupTime" defaultValue={wakeupTime} required/>
            <label htmlFor="schoolStartTime">Schul-/Arbeitsbeginn:</label>
            <input type="time" id="schoolStartTime" name="schoolStartTime" defaultValue={schoolStartTime} required/>
            
            <label htmlFor="lunchTime">Beginn Mittagspause:</label>
            <input type="time" id="lunchTime" name="lunchTime" defaultValue={lunchTime} required/>

            <label htmlFor="lunchEndTime">Ende Mittagspause:</label>
            <input type="time" id="lunchEndTime" name="lunchEndTime" defaultValue={lunchEndTime} required/>

            <label htmlFor="schoolEndTime">Schul-/Arbeitsende:</label>
            <input type="time" id="schoolEndTime" name="schoolEndTime" defaultValue={schoolEndTime} required/>
            <label htmlFor="sleepTime">Schlafenszeit:</label>
            <input type="time" id="sleepTime" name="sleepTime" defaultValue={sleepTime} required/>
            
            <button id='freeTimesButton' onClick={() => initiateFreeTimesWindow()}>Zwischenstunden verwalten</button>
            <button type="submit" className="okButton">Weiter</button>

            </div>
            <label className='errorLabel' id='errorLabelEdit'></label>
            <input type='hidden' id='sleepAfterMidnightID' name='sleepAfterMidnight' value={0} />
          </form>
          <button className='resetButton' onClick={() => resetPopup()}>Kalender zurücksetzen</button>
        </div>
        </>
    );
  } else if (currentScreen === 'freeHoursNew') {
      let percentage = Math.round((currentDayIndex / 6) * 90) + 4;
      return (
        <>
        <ProgressBar percentage={percentage} />
        <button onClick={() => handleBack()}>Zurück</button>
        <FreeHoursInput schedule={schedule} scheduleFunction={setSchedule} screenFunction={setCurrentScreen} currentDay={days[currentDayIndex]}/>
        </>
      )
  } else if (currentScreen === 'activity') {
      let percentage = Math.round((currentDayIndex / 6) * 90) + 8;
      return (
        <>
          <ProgressBar percentage={percentage} />
        <div className="ActivityScreen">
          
          <div className="activityBox">
          
          <button onClick={() => handleBack()}>Zurück</button>
            <p>Hast du sonst noch Aktivitäten, die am {days[currentDayIndex]} regelmässig stattfinden?</p>
            <div className="activityNavigation">
              <button className="okButton" onClick={activityYes}>Ja</button>
              <button className="okButton" onClick={activityNo}>Nein</button>
            </div>
            <button className='manageButton' onClick={() => setCurrentScreen('activityManagement')}>Aktivitäten verwalten</button>
          </div>
        </div>
        <button className='resetButton' onClick={() => resetPopup()}>Kalender zurücksetzen</button>
        </>
      );
  } else if (currentScreen === 'activityManagement') {
    return (
      <div>
        <button onClick={() => handleBack() }>Zurück</button>
        <ActivityManagement schedule={schedule} scheduleFunction={setSchedule} screenFunction={setCurrentScreen} currentDay={days[currentDayIndex]} />
      </div>
    );
  }
  else if (currentScreen === 'activityYes') {

    const isTimeAfter = (time1: string, time2: string): boolean => {
      return time1.localeCompare(time2) > 0;
    };

    const isTimeBefore = (time1: string, time2: string): boolean => {
        return time1.localeCompare(time2) < 0;
    };

    let percentage = Math.round((currentDayIndex / 6) * 90) + 8;

    return (
      <>
        <ProgressBar percentage={percentage} />
      <div className="ActivityScreen">
        <div className="activityBox">
        <button onClick={() => handleBack()}>Zurück</button>
          <p>Was für eine Aktivität hast du am {days[currentDayIndex]}?</p>
          <form onSubmit={(e) => {
            e.preventDefault();
            let wakeUpTime = schedule[days[currentDayIndex]]['wakeUpTime'];
            let sleepTime = schedule[days[currentDayIndex]]['sleepTime'];
            const formData = new FormData(e.target as HTMLFormElement);
            const type = formData.get('activityType') as string;
            const startTime = formData.get('startTime') as string;
            const endTime = formData.get('endTime') as string;
            const activityName = formData.get('activityName') as string;
            const errorLabel = document.getElementById('errorLabel') as HTMLLabelElement;
            if (isTimeBefore(startTime, wakeUpTime) || isTimeAfter(endTime, sleepTime)) {
              errorLabel.innerHTML = 'Aktivität muss zwischen Aufwachzeit und Schlafenszeit liegen.';
              return;
            } else if (isTimeAfter(startTime, endTime)) {
              errorLabel.innerHTML = 'Endzeit muss nach Anfangszeit liegen.';
              return;
            }
            handleAddActivity(days[currentDayIndex], type, startTime, endTime, activityName);
          }}>
            <label htmlFor="activityType">Art der Aktivität:</label>
            <select name="activityType" id="activityType" required>
              <option value="Sport">Sport</option>
              <option value="Bildung">Bildung</option>
              <option value="Bildung">Musik</option>
              <option value="Freizeit">Anders</option>
            </select>

            <label htmlFor="activityName">Name der Aktivität:</label>
            <input type="text" id="activityName" name="activityName" placeholder="Name der Aktivität" required/>
  
            <label htmlFor="startTime">Startzeit:</label>
            <input type="time" id="startTime" name="startTime" required></input>
  
            <label htmlFor="endTime">Endzeit:</label>
            <input type="time" id="endTime" name="endTime" required></input>

            <label className='errorLabel' id='errorLabel'></label>
  
            <button type="submit" className="okButton">OK</button>
          </form>
        </div>
      </div>
      <button className='resetButton' onClick={() => resetPopup()}>Kalender zurücksetzen</button>
      </>
    );
  } else if (currentScreen === 'reachableQuestion') {
    function reachableButton(reachable) {
      setReachable(reachable === "Yes");
      setCurrentScreen('SubmitScreen');
    }
    return (
      <div className="QuestionScreen">
        <div className="QuestionBox">
          <p>Können wir dich während der Zwischenstunden erreichen?</p>
          <div className="submitNavigation">
            <button onClick={() => reachableButton("Yes")}>Ja</button>
            <button onClick={() => reachableButton("No")}>Nein</button>
          </div>
        </div>
      </div>
    );
  }
  else if (currentScreen === 'SubmitScreen') {
    console.log('reachable', reachable);
    submitSchedule(schedule);
    setCurrentScreen('apiResponse');
  } else if (currentScreen === 'feedback' && apiResponse === '') {
    return (
      <div className='survey'>
        <div className='question-text'>Dein Zeitplan wird gerade eingereicht!</div>
      </div>
    )
  } else if (currentScreen === 'feedback' && apiResponse !== '') {
    return (
      <div className='survey'>
                <form 
                onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target as HTMLFormElement);
                const feedback = formData.get('feedback');
                handleFeedback(feedback);
                }} 
                className="durationForm"
            >
                <div className="formField">
                <div className='survey-container'>
                    <div className='feedback-text'>Wir befinden uns momentan in der Testphase. Bitte teile uns Probleme mit, die dir beim Ausfüllen des Kalenders aufgefallen sind.</div>
                    <textarea id="feedback" name="feedback" rows={5} placeholder="Dein Feedback"></textarea>
                </div>
                </div>
            
                <div className="submitContainer">
                <button type="submit" className="okButton">Einreichen</button>
                <button className="negativeButton" onClick={() => setCurrentScreen('apiResponse')}>Überspringen</button>
                </div>
            </form>
            </div>
    )
  } else if (currentScreen === 'feedbackAnswer') {
    return (
      <div className='survey'>
        <div className='question-text'>Vielen Dank für dein Feedback!</div>
      </div>
    )
  } else if (currentScreen === 'apiResponse') {
    return (
      <div className="QuestionScreen">
        <div className="QuestionBox">
          <p>{apiResponse}</p>
          <div className="submitNavigation">
          </div>
        </div>
      </div>
    );
  } else if (currentScreen === 'ReviewScreen') {
        const handleAcceptTimeTable = () => {
          //loop through all days and check if there is a single freehour array that's not empty
          let freeHoursExist = false;
          for (let day in schedule) {
            if (schedule[day]['freeHours'].length > 0) {
              freeHoursExist = true;
              break;
            }
          }
          if (freeHoursExist) {
            setCurrentScreen('reachableQuestion');
          } else {
            setCurrentScreen('SubmitScreen');
          }
        };
    
        const handleRejectTimeTable = () => {
          setCurrentScreen('calendarEdit');
        };
        return (
        <div className="QuestionScreen">
                        <button onClick={() => handleBack()}>Zurück</button>

            <div id='timetable-overview'>
            <TimetableComponent schedule={schedule} />
            </div>
              <div className="questionBox">
                <p>Stimmt die oben genannte Zeitabelle?</p>
                <div className="questionNavigation">
                  <button className="okButton" onClick={handleAcceptTimeTable}>Ja</button>
                  <button className="negativeButton" onClick={handleRejectTimeTable}>Nein, ich will etwas ändern.</button>
                </div>
              </div>
          </div>
          );
        }

  else if (currentScreen === 'calendarEdit') {
    function skipSchoolEdit() {
      const skipSchoolElement = document.getElementById('skipSchool') as HTMLInputElement;
      //if checked
      if (skipSchoolElement && skipSchoolElement.checked) {
        const schoolStartTimeElement = document.getElementById('schoolStartTime');
        if (schoolStartTimeElement) {
          schoolStartTimeElement.setAttribute('disabled', 'true');
        }
        const schoolEndTimeElement = document.getElementById('schoolEndTime');
        if (schoolEndTimeElement) {
          schoolEndTimeElement.setAttribute('disabled', 'true');
        }
      } else {
        const schoolStartTimeElement = document.getElementById('schoolStartTime');
        if (schoolStartTimeElement) {
          schoolStartTimeElement.removeAttribute('disabled');
        }
        const schoolEndTimeElement = document.getElementById('schoolEndTime');
        if (schoolEndTimeElement) {
          schoolEndTimeElement.removeAttribute('disabled');
        }
      }
    }
    function skipLunchEdit() {

      const skipLunchElement = document.getElementById('skipLunch') as HTMLInputElement;
      //if checked

      if (skipLunchElement && skipLunchElement.checked) {
        const lunchTimeElement = document.getElementById('lunchTime');
        const lunchEndTimeElement = document.getElementById('lunchEndTime');
        if (lunchTimeElement && lunchEndTimeElement) {
          lunchTimeElement.setAttribute('disabled', 'true');
          lunchEndTimeElement.setAttribute('disabled', 'true');
        }
      } else {
        const lunchTimeElement = document.getElementById('lunchTime');
        const lunchEndTimeElement = document.getElementById('lunchEndTime');
        if (lunchTimeElement && lunchEndTimeElement) {
          lunchTimeElement.removeAttribute('disabled');
          lunchEndTimeElement.removeAttribute('disabled');
        }
      }
    }

    function sleepAfterMidnightEdit() {
      const sleepAfterMidnightElement = document.getElementById('sleepAfterMidnightCheckbox') as HTMLInputElement;
      //if checked
      if (sleepAfterMidnightElement && sleepAfterMidnightElement.checked) {
        const sleepAfterMidnightID = document.getElementById('sleepAfterMidnightID');
        if (sleepAfterMidnightID) {
          sleepAfterMidnightID.setAttribute('value', '1');
        }
      } else {
        const sleepAfterMidnightID = document.getElementById('sleepAfterMidnightID');
        if (sleepAfterMidnightID) {
          sleepAfterMidnightID.setAttribute('value', '0');
        }
      }
    } 

    const convertMinutesToTime = (totalMinutes) => {
      if (totalMinutes == null) return null;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
  
      // Format hours and minutes to always be two digits
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
  
      return `${formattedHours}:${formattedMinutes}`;
  };

    if (editingDayIndex != -1) {
      //get all values from the day in the schedule and list them
      let wakeupTime = schedule[days[editingDayIndex]]['wakeUpTime'];
      let schoolStartTime = schedule[days[editingDayIndex]]['schoolStartTime'];
      let lunchTime = schedule[days[editingDayIndex]]['lunchTime'];
      let lunchEndTime = schedule[days[editingDayIndex]]['lunchEndTime'];
      let schoolEndTime = schedule[days[editingDayIndex]]['schoolEndTime'];
      let sleepTime = schedule[days[editingDayIndex]]['sleepTime'];

      return (
        <div className='editDayScreen'>
          <button onClick={() => handleBack()}>Zurück</button>
          <form onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const wakeupTime = formData.get('wakeupTime');
            let schoolStartTime = formData.get('schoolStartTime');
            let lunchTime = formData.get('lunchTime');
            let lunchEndTime = formData.get('lunchEndTime');
            let schoolEndTime = formData.get('schoolEndTime');
            let sleepTime = formData.get('sleepTime');
            let sleepAfterMidnight = formData.get('sleepAfterMidnight');

            const errorLabel = document.getElementById('errorLabelEdit');

            const skipSchoolElement = document.getElementById('skipSchool') as HTMLInputElement;
            const skipLunchElement = document.getElementById('skipLunch') as HTMLInputElement;

            if (skipSchoolElement && skipSchoolElement.checked) {
              schoolStartTime = null;
              schoolEndTime = null;
            } 

            if (skipLunchElement && skipLunchElement.checked) {
              lunchTime = null;
              lunchEndTime = null;
            }

            console.log('sleepAfterMidnight', sleepAfterMidnight);

            if (sleepAfterMidnight && sleepAfterMidnight == '1' && sleepTime != null) {
              sleepTime = convertTimeToMinutes(sleepTime) + 1440;
              sleepTime = convertMinutesToTime(sleepTime);
              console.log('sleepTime', sleepTime);
            }


            if (errorLabel) {
              //school starttime and lunch and schoolend time and sleep time should be after wakeup time
              if (schoolStartTime != null && convertTimeToMinutes(schoolStartTime) < convertTimeToMinutes(wakeupTime)) {
                errorLabel.innerHTML = 'Schul-/Arbeitsbeginn muss nach dem Aufwachen liegen.';
                return;
              } else if (lunchTime != null && convertTimeToMinutes(lunchTime) < convertTimeToMinutes(wakeupTime)) {
                errorLabel.innerHTML = 'Mittagspause muss nach dem Aufwachen liegen.';
                return;
              } else if (lunchEndTime != null && convertTimeToMinutes(lunchEndTime) < convertTimeToMinutes(lunchTime)) {
                errorLabel.innerHTML = 'Mittagspauseende muss nach dem Mittagspausebeginn liegen.';
                return;
              } 
              else if ( schoolEndTime != null && convertTimeToMinutes(schoolEndTime) < convertTimeToMinutes(schoolStartTime)) {
                errorLabel.innerHTML = 'Schul-/Arbeitsende muss nach dem Schul-/Arbeitsbeginn liegen.';
                return;
              } else if (schoolEndTime != null && convertTimeToMinutes(sleepTime) < convertTimeToMinutes(schoolEndTime) && !sleepAfterMidnight) {
                errorLabel.innerHTML = 'Schlafenszeit muss nach dem Schul-/Arbeitsende liegen.';
                return;
              } else if (convertTimeToMinutes(sleepTime) < convertTimeToMinutes(wakeupTime)) {
                errorLabel.innerHTML = 'Schlafenszeit muss nach dem Aufwachen liegen.';
                return;
              } else {
                errorLabel.innerHTML = '';
              }
            }

            let updatedSchedule = {
              ...schedule,
              [days[editingDayIndex]]: {
                ...schedule[days[editingDayIndex]],
                wakeUpTime: wakeupTime,
                schoolStartTime: schoolStartTime,
                lunchTime: lunchTime,
                lunchEndTime: lunchEndTime,
                schoolEndTime: schoolEndTime,
                sleepTime: sleepTime
              }
            };
            setSchedule(updatedSchedule);
            setEditingDayIndex(-1);
            setCurrentScreen('ReviewScreen');
          }}>
            <div className='checkboxContainer'>
              <div className='checkboxLabel'>
                <input type='checkbox' id='skipSchool' onClick={skipSchoolEdit} name='skipSchool' value='skipSchool' />
                <label htmlFor="skipSchool">Keine Schule/Arbeit</label>
              </div>
              <div className='checkboxLabel'>
                <input type='checkbox' id='skipLunch' onClick={skipLunchEdit} name='skipLunch' value='skipLunch' />
                <label htmlFor="skipLunch">Keine Mittagspause</label>
              </div>
              <div className='checkboxLabel'>
                <input type='checkbox' id='sleepAfterMidnightCheckbox' onClick={sleepAfterMidnightEdit} name='sleepAfterMidnightCheckbox' value='sleepAfterMidnight' />
                <label htmlFor="sleepAfterMidnightCheckbox">Ich schlafe nach Mitternacht</label>
              </div>
            </div>
            <div className='dayLabels'>
            <label htmlFor="wakeupTime">Aufwachzeit:</label>
            <input type="time" id="wakeupTime" name="wakeupTime" defaultValue={wakeupTime} required/>
            <label htmlFor="schoolStartTime">Schul-/Arbeitsbeginn:</label>
            <input type="time" id="schoolStartTime" name="schoolStartTime" defaultValue={schoolStartTime} required/>
            
            <label htmlFor="lunchTime">Beginn Mittagspause:</label>
            <input type="time" id="lunchTime" name="lunchTime" defaultValue={lunchTime} required/>
            <label htmlFor="lunchEndTime">Ende Mittagspause:</label>
            <input type="time" id="lunchEndTime" name="lunchEndTime" defaultValue={lunchEndTime} required/>
            <label htmlFor="schoolEndTime">Schul-/Arbeitsende:</label>
            <input type="time" id="schoolEndTime" name="schoolEndTime" defaultValue={schoolEndTime} required/>
            <label htmlFor="sleepTime">Schlafenszeit:</label>
            <input type="time" id="sleepTime" name="sleepTime" defaultValue={sleepTime} required/>
            <label className='errorLabel' id='errorLabelEdit'></label>
            <button type="submit" className="okButton">OK</button>
            </div>
            <input type='hidden' id='sleepAfterMidnightID' name='sleepAfterMidnight' value={0} />
          </form>
        </div>
      )
            
    } else {
      return (
      <div className='QuestionScreen'>
        <button onClick={() => handleBack()}>Zurück</button>
        <div className='questionBox'>
          <p>Was möchtest du bearbeiten?</p>
        <div className='dayButtons'>
          <button onClick={() => setEditingDayIndex(0)}>Montag</button>
          <button onClick={() => setEditingDayIndex(1)}>Dienstag</button>
          <button onClick={() => setEditingDayIndex(2)}>Mittwoch</button>
          <button onClick={() => setEditingDayIndex(3)}>Donnerstag</button>
          <button onClick={() => setEditingDayIndex(4)}>Freitag</button>
          <button onClick={() => setEditingDayIndex(5)}>Samstag</button>
          <button onClick={() => setEditingDayIndex(6)}>Sonntag</button>
        </div>
      </div>
      </div>
      )
    }
  }
  else {
  }
}
export default Calendar;