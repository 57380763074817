import React, { useState } from 'react';
import styles from './Survey.module.css'; // Import the CSS module

const Survey: React.FC<{ onSubmit: (data: any) => void }> = ({ onSubmit }) => {
  const [page, setPage] = useState(1);
  const [error, setError] = useState<string | null>(null); // Error state

  const stimmeQuestions = [
    'In den meisten Bereichen entspricht mein Leben meinen Idealvorstellungen.',
    'Meine Lebensbedingungen sind ausgezeichnet.',
    'Ich bin mit meinem Leben zufrieden.',
    'Bisher habe ich die wesentlichen Dinge erreicht, die ich mir für mein Leben wünsche.',
    'Wenn ich mein Leben noch einmal leben könnte, würde ich kaum etwas ändern.',
  ];

  const satisfactionQuestions = [
    'mit Ihrer Gesundheit?',
    'mit Ihrem Schlaf?',
    'mit Ihrer Arbeit? (Falls erwerbstätig)?',
    'mit Ihrer Tätigkeit im Haushalt? (Falls im Haushalt tätig)?',
    'mit dem Einkommen Ihres Haushalts?',
    'mit Ihrem persönlichen Einkommen?',
    'mit Ihrer Wohnsituation?',
    'mit Ihrer Freizeit?',
    'mit den vorhandenen Möglichkeiten der Kinderbetreuung? (falls Kinder im Vorschulalter)?',
    'mit Ihrem Familienleben?',
    'mit Ihrer Schul- und Berufsausbildung?',
  ];

  const teilsTeilsQuestions = [
    'Ich bin eher ruhig.',
    'Ich bin einfühlsam, warmherzig.',
    'Ich bin eher unordentlich.',
    'Ich mache mir oft Sorgen.',
    'Ich kann mich für Kunst, Musik und Literatur begeistern.',
    'Ich neige dazu, die Führung zu übernehmen.',
    'Ich bin manchmal unhöflich und schroff.',
    'Ich neige dazu, Aufgaben vor mir herzuschieben.',
    'Ich bin oft deprimiert, niedergeschlagen.',
    'Mich interessieren abstrakte Überlegungen wenig.',
    'Ich bin voller Energie und Tatendrang.',
    'Ich schenke anderen leicht Vertrauen, glaube an das Gute im Menschen.',
    'Ich bin verlässlich, auf mich kann man zählen.',
    'Ich bin ausgeglichen, nicht leicht aus der Ruhe zu bringen.',
    'Ich bin originell, entwickle neue Ideen.',
    'Ich gehe aus mir heraus, bin gesellig.',
    'Andere sind mir eher gleichgültig, egal.',
    'Ich mag es sauber und aufgeräumt.',
    'Ich bleibe auch in stressigen Situationen gelassen.',
    'Ich bin nicht sonderlich kunstinteressiert.',
    'In einer Gruppe überlasse ich lieber anderen die Entscheidung.',
    'Ich begegne anderen mit Respekt.',
    'Ich bleibe an einer Aufgabe dran, bis sie erledigt ist.',
    'Ich bin selbstsicher, mit mir zufrieden.',
    'Es macht mir Spass, gründlich über komplexe Dinge nachzudenken und sie zu verstehen.',
    'Ich bin weniger aktiv und unternehmungslustig als andere.',
    'Ich neige dazu, andere zu kritisieren.',
    'Ich bin manchmal ziemlich nachlässig.',
    'Ich reagiere schnell gereizt oder genervt.',
    'Ich bin nicht besonders einfallsreich.'
  ];

  const stimmeOptions = [
    'stimme überhaupt nicht zu',
    'stimme nicht zu',
    'stimme eher nicht zu',
    'weder/noch',
    'stimme eher zu',
    'stimme zu',
    'stimme völlig zu',
  ];

  const teilsTeilsOptions = [
    'Stimme überhaupt nicht zu',
    'Stimme eher nicht zu',
    'Teils, teils',
    'Stimme eher zu',
    'Stimme voll und ganz zu',
  ];

  const [stimmeAnswers, setStimmeAnswers] = useState<string[]>(new Array(stimmeQuestions.length).fill(''));
  const [satisfactionAnswers, setSatisfactionAnswers] = useState<number[]>(new Array(satisfactionQuestions.length).fill(5)); // Default value 5 for sliders
  const [teilsTeilsAnswers, setTeilsTeilsAnswers] = useState<string[]>(new Array(teilsTeilsQuestions.length).fill(''));

  const handleAnswerChange = (
    questionType: 'stimme' | 'satisfaction' | 'teilsTeils',
    questionIndex: number,
    answer: any
  ) => {
    if (questionType === 'stimme') {
      const updatedAnswers = [...stimmeAnswers];
      updatedAnswers[questionIndex] = answer;
      setStimmeAnswers(updatedAnswers);
    } else if (questionType === 'satisfaction') {
      const updatedAnswers = [...satisfactionAnswers];
      updatedAnswers[questionIndex] = answer;
      setSatisfactionAnswers(updatedAnswers);
    } else if (questionType === 'teilsTeils') {
      const updatedAnswers = [...teilsTeilsAnswers];
      updatedAnswers[questionIndex] = answer;
      setTeilsTeilsAnswers(updatedAnswers);
    }
  };

  const validateAnswers = (answers: any[]) => answers.every((answer) => answer !== '');

  const handleNextPage = () => {
    if (page === 1) {
      if (!validateAnswers(stimmeAnswers) || !validateAnswers(satisfactionAnswers)) {
        setError('Bitte beantworten Sie alle Fragen, bevor Sie fortfahren.');
        return;
      }
    }
    setError(null);
    setPage((prevPage) => prevPage + 1);

    // Scroll to top after navigating to the next page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = () => {
    if (!validateAnswers(teilsTeilsAnswers)) {
      setError('Bitte beantworten Sie alle Fragen, bevor Sie das Formular abschicken.');
      return;
    }

    setError(null); // Clear error message
    const surveyData = { stimmeAnswers, satisfactionAnswers, teilsTeilsAnswers };
    onSubmit(surveyData);
  };

  return (
    <div className={styles.surveyContainer}>
      {page === 1 && (
        <>
          <h4 className={styles.header}>Bitte wählen Sie die Antwortalternative, die für Sie am ehesten zutrifft.</h4>
          {stimmeQuestions.map((question, index) => (
            <div key={index} className={styles.questionBlock}>
              <h3 className={styles.questionTitle}>{question}</h3>
              {stimmeOptions.map((option) => (
                <label key={option} className={styles.label}>
                  <input
                    type="radio"
                    name={`stimme-question-${index}`}
                    value={option}
                    checked={stimmeAnswers[index] === option}
                    onChange={() => handleAnswerChange('stimme', index, option)}
                    className={styles.radioInput}
                  />
                  {option}
                </label>
              ))}
            </div>
          ))}

          <h4 className={styles.subheader}>
            Wie zufrieden sind Sie gegenwärtig mit den folgenden Bereichen Ihres Lebens? Wie zufrieden sind Sie...
          </h4>
          {satisfactionQuestions.map((question, index) => (
            <div key={index} className={styles.questionBlock}>
              <h3 className={styles.questionTitle}>{question}</h3>
              <div className={styles.sliderContainer}>
                <input
                  type="range"
                  min="0"
                  max="10"
                  value={satisfactionAnswers[index]}
                  onChange={(e) => handleAnswerChange('satisfaction', index, Number(e.target.value))}
                  className={styles.slider}
                />
                <div className={styles.sliderValues}>
                  <span>0 – ganz und gar unzufrieden</span>
                  <span>10 – ganz und gar zufrieden</span>
                </div>
              </div>
              <p className={styles.centerText}>Antwort: {satisfactionAnswers[index]}</p>
            </div>
          ))}

          {error && <div className={styles.errorMessage}>{error}</div>}
          <button type="button" className={styles.button} onClick={handleNextPage}>
            Weiter
          </button>
        </>
      )}

      {page === 2 && (
        <>
          <h4 className={styles.header}>
            Nachstehend finden Sie eine Reihe von Eigenschaften, die auf Sie zutreffen könnten. Bitte geben Sie für jede
            der folgenden Aussagen an, inwieweit Sie zustimmen.
          </h4>

          {teilsTeilsQuestions.map((question, index) => (
            <div key={index} className={styles.questionBlock}>
              <h3 className={styles.questionTitle}>{question}</h3>
              {teilsTeilsOptions.map((option) => (
                <label key={option} className={styles.label}>
                  <input
                    type="radio"
                    name={`teils-teils-question-${index}`}
                    value={option}
                    checked={teilsTeilsAnswers[index] === option}
                    onChange={() => handleAnswerChange('teilsTeils', index, option)}
                    className={styles.radioInput}
                  />
                  {option}
                </label>
              ))}
            </div>
          ))}

          {error && <div className={styles.errorMessage}>{error}</div>}
          <button type="button" className={styles.button} onClick={handleSubmit}>
            Submit
          </button>
        </>
      )}
    </div>
  );
};

export default Survey;
